<template>
  <div class="container">
    <el-switch placeholder="Active" v-model="checked" active-text="Active" inactive-text="Inactive"></el-switch>
    <p />
    <el-input
      placeholder="First Name"
      v-model="firstname"
      clearable
      :rules="[v => !!v || 'First Name is required']"
    ></el-input>
    <el-input
      placeholder="Last Name"
      v-model="lastname"
      clearable
      :rules="[v => !!v || 'Last Name is required']"
    ></el-input>
    <el-input placeholder="Mobile Number" v-model="mobile" clearable></el-input>
    <el-input placeholder="Landline" v-model="landline" clearable></el-input>
    <el-input placeholder="E-mail" v-model="email" clearable></el-input>
    <el-select
      v-model="specialty"
      placeholder="Specialty"
      :rules="[v => !!v || 'Specialty is required']"
    >
      <el-option
        v-for="item in specialtyList"
        :key="item.id"
        :label="item.literal"
        :value="item.id"
      ></el-option>
    </el-select>
    <el-select
      v-model="WoSpecialty"
      placeholder="WOSpecialty"
      :rules="[v => !!v || 'WOSpecialty is required']"
    >
      <el-option
        v-for="item in WOSpecialtyList"
        :key="item.id"
        :label="item.literal"
        :value="item.id"
      ></el-option>
    </el-select>
    <br />
    <el-button type="success" round @click.native="addToDB">Save</el-button>
    <el-button type="danger" round @click.native="back">Back</el-button>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Specialty } from "../../api/personnel/specialty";
import { personnel } from "../../api/personnel/personnel";
import { WOSpecialty } from "../../api/personnel/wospecialty";

@Component({
  name: "AddPersonnel",
})
export default class AddPersonnel extends Vue {
  public specialtyList: any[] = [];
  public firstname: string = "";
  public lastname: string = "";
  public mobile: string = "";
  public landline: string = "";
  public email: string = "";
  public specialty: string = "";
  public WoSpecialty: string = "";
  public WOSpecialtyList: any[] = [];
  public checked: boolean = true;
  row: any;

  public async getSpecialtyList() {
    Specialty.getAllSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then((response) => {
      response.json().then((resp) => {
        this.specialtyList = JSON.parse(JSON.stringify(resp));
      });
    });
  }

  public async getWOSpecialtyList() {
    WOSpecialty.getAllWOSpecialties(
      this.$store.getters.getToken,
      this.$store.getters.getHost
    ).then((response) => {
      response.json().then((resp) => {
        this.WOSpecialtyList = JSON.parse(JSON.stringify(resp));
      });
    });
  }

  public mounted() {
    this.row = this.$store.getters.getCurrentObject;

    if (this.row) {
      // Populate fields if editing
      this.firstname = this.row.firstname;
      this.lastname = this.row.lastname;
      this.mobile = this.row.mobile || "";
      this.landline = this.row.landline || "";
      this.email = this.row.email || "";
      this.specialty = this.row.specialty_id;
      this.WoSpecialty = this.row.wo_specialty;
      this.checked = this.row.active || false;
    }

    this.getSpecialtyList();
    this.getWOSpecialtyList();
  }

  public async addToDB() {
    if (!this.firstname || !this.lastname || !this.specialty || !this.WoSpecialty) {
      alert("Please fill in all required fields!");
      return;
    }

    let personnelItem = {
      firstname: this.firstname,
      lastname: this.lastname,
      email: this.email,
      mobile: this.mobile,
      landline: this.landline,
      company_id: 1, // Default to company_id 1
      specialty_id: this.specialty,
      active: this.checked,
      wo_specialty: this.WoSpecialty,
    };

    if (this.row && this.row.id) {
      // Update existing personnel
      personnelItem["id"] = this.row.id;

      await personnel.editStaff(
        this.$store.getters.getToken,
        personnelItem,
        this.$store.getters.getHost
      );
    } else {
      // Add new personnel
      await personnel.addNewStaff(
        this.$store.getters.getToken,
        personnelItem,
        this.$store.getters.getHost
      );
    }

    // Navigate back to the personnel list
    this.$store.dispatch("updateCurrentComponent", "PersonnelList");
    this.$router.replace({ name: "load" });
  }

  public back() {
    this.$store.dispatch("updateCurrentObject", undefined);
    this.$store.dispatch("updateCurrentComponent", "PersonnelList");
    this.$store.dispatch("setCurrentId", -1);
    this.$router.replace({ name: "load" });
  }
}
</script>

<style scoped>
.container {
  border: 2px solid rgb(170, 238, 238);
  border-radius: 10px;
  background-color: rgb(248, 247, 247);
  padding: 5px 10px 5px 10px;
}
</style>
