import { render, staticRenderFns } from "./AddPersonnel.vue?vue&type=template&id=796b7392&scoped=true"
import script from "./AddPersonnel.vue?vue&type=script&lang=ts"
export * from "./AddPersonnel.vue?vue&type=script&lang=ts"
import style0 from "./AddPersonnel.vue?vue&type=style&index=0&id=796b7392&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796b7392",
  null
  
)

export default component.exports